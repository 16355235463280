



// // new code
// import React from 'react';
// import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './SliderEcoProduct.css'; 
// import EcoImg1 from '../assets/EcoProduct/EcoProduct-1.jpg';
// import EcoImg2 from '../assets/EcoProduct/EcoProduct-2.jpg';
// import EcoImg3 from '../assets/EcoProduct/EcoProduct-3.jpg';
// import EcoImg4 from '../assets/EcoProduct/EcoProduct-4.jpg';
// import EcoImg5 from '../assets/EcoProduct/EcoProduct-5.jpg';
// import EcoImg6 from '../assets/EcoProduct/EcoProduct-6.jpg';
// import EcoImg7 from '../assets/EcoProduct/EcoProduct-7.jpg';
// import EcoImg8 from '../assets/EcoProduct/EcoProduct-8.jpg';
// import EcoImg9 from '../assets/EcoProduct/EcoProduct-9.jpg';
// import EcoImg10 from '../assets/EcoProduct/EcoProduct-10.jpg';
// import EcoImg11 from '../assets/EcoProduct/EcoProduct-11.jpg';
// import EcoImg12 from '../assets/EcoProduct/EcoProduct-12.jpg';

// const SliderEcoProduct = () => {
//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 3000,
//         cssEase: "linear",
//         responsive: [
//             {
//                 breakpoint: 1024, // Tablets
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 1,
//                 }
//             },
//             {
//                 breakpoint: 768, // Small tablets and large phones
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 1,
//                 }
//             },
//             {
//                 breakpoint: 480, // Mobile devices
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 }
//             }
//         ]
//     };

//     const images = [
//         { src: EcoImg1, alt: 'Eco Product 1' },
//         { src: EcoImg2, alt: 'Eco Product 2' },
//         { src: EcoImg3, alt: 'Eco Product 3' },
//         { src: EcoImg4, alt: 'Eco Product 4' },
//         { src: EcoImg5, alt: 'Eco Product 5' },
//         { src: EcoImg6, alt: 'Eco Product 6' },
//         { src: EcoImg7, alt: 'Eco Product 7' },
//         { src: EcoImg8, alt: 'Eco Product 8' },
//         { src: EcoImg9, alt: 'Eco Product 9' },
//         { src: EcoImg10, alt: 'Eco Product 10' },
//         { src: EcoImg11, alt: 'Eco Product 11' },
//         { src: EcoImg12, alt: 'Eco Product 12' },
//     ];

//     return (
//         <div>
//         <div className="slider-container" id='eco-product-slider'>
//             <Slider {...settings}>
//                 {images.map((image, index) => (
//                     <div key={index} className="slider-item">
//                         <img src={image.src} alt={image.alt} className="slider-image" />
//                     </div>
//                 ))}
//             </Slider>

//         </div>

//         <button className="gallery-card-btn" id='gallery-card-btn-slider' >
//                 <Link to= '/eco-products'>   Show All </Link> 
//                 </button>
        
//         </div>
//     );
// };

// export default SliderEcoProduct;



// new code 

import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SliderEcoProduct.css'; 
import EcoImg1 from '../assets/EcoProduct/EcoProduct-1.jpg';
import EcoImg2 from '../assets/EcoProduct/EcoProduct-2.jpg';
import EcoImg3 from '../assets/EcoProduct/EcoProduct-3.jpg';
import EcoImg4 from '../assets/EcoProduct/EcoProduct-4.jpg';
import EcoImg5 from '../assets/EcoProduct/EcoProduct-5.jpg';
import EcoImg6 from '../assets/EcoProduct/EcoProduct-6.jpg';
import EcoImg7 from '../assets/EcoProduct/EcoProduct-7.jpg';
import EcoImg8 from '../assets/EcoProduct/EcoProduct-8.jpg';
import EcoImg9 from '../assets/EcoProduct/EcoProduct-9.jpg';
import EcoImg10 from '../assets/EcoProduct/EcoProduct-10.jpg';
import EcoImg11 from '../assets/EcoProduct/EcoProduct-11.jpg';
import EcoImg12 from '../assets/EcoProduct/EcoProduct-12.jpg';
import EcoImg13 from '../assets/EcoProduct/achuk-product-certification.jpeg';
import EcoImg14 from '../assets/EcoProduct/Eri-Silk-Eco-Product.jpg';

const SliderEcoProduct = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024, // Tablets
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768, // Small tablets and large phones
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480, // Mobile devices
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    // Reverse the images array
    const images = [
        { src: EcoImg1, alt: 'Eco Product 1' },
        { src: EcoImg2, alt: 'Eco Product 2' },
        { src: EcoImg3, alt: 'Eco Product 3' },
        { src: EcoImg4, alt: 'Eco Product 4' },
        { src: EcoImg5, alt: 'Eco Product 5' },
        { src: EcoImg6, alt: 'Eco Product 6' },
        { src: EcoImg7, alt: 'Eco Product 7' },
        { src: EcoImg8, alt: 'Eco Product 8' },
        { src: EcoImg9, alt: 'Eco Product 9' },
        { src: EcoImg10, alt: 'Eco Product 10' },
        { src: EcoImg11, alt: 'Eco Product 11' },
        { src: EcoImg12, alt: 'Eco Product 12' },
        { src: EcoImg13, alt: 'Eco Product 13' },
        { src: EcoImg14, alt: 'Eco Product 14' },
    ];

    const reversedImages = [...images].reverse();  // Reverse the order of images

    return (
        <div>
            <div className="slider-container" id='eco-product-slider'>
                <Slider {...settings}>
                    {reversedImages.map((image, index) => (
                        <div key={index} className="slider-item">
                            <img src={image.src} alt={image.alt} className="slider-image" />
                        </div>
                    ))}
                </Slider>
            </div>

            <button className="gallery-card-btn" id='gallery-card-btn-slider'>
                <Link to='/eco-products'>Show All</Link>
            </button>
        </div>
    );
};

export default SliderEcoProduct;
