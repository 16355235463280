// import React, { useState, useEffect } from 'react';
// import './PaymentForm.css'; // Import the CSS
// import { Link } from 'react-router-dom';

// const PaymentForm = () => {
//   // Initial table data
//   const initialItems = [
//     { id: 1, title: 'GEM Sustainability Program & GEM CP Exam - For Students and Faculty members (Rs. 4500/- + 18 % GST)', amount: 5310.00 },
//     { id: 2, title: 'GEM Sustainability Program & GEM CP Exam - For Professionals (Rs. 6000/- + 18 % GST)', amount: 7080.00 },
//   ];

//   // State to manage items in the cart
//   const [cart, setCart] = useState([]);

//   // Load cart data from local storage when the component mounts
//   useEffect(() => {
//     const savedCart = JSON.parse(localStorage.getItem('cart'));
//     if (savedCart) {
//       setCart(savedCart);
//     }
//   }, []);
//   console.log(cart)

//   // Save cart data to local storage whenever the cart changes
//   useEffect(() => {
//     if (cart.length > 0) {
//       localStorage.setItem('cart', JSON.stringify(cart));
//     }
//   }, [cart]);

//   // Handle adding items to the cart (only one item at a time)
//   const addToCart = (item) => {
//     // Only add the item if it's not already in the cart
//     setCart([item]);
//   };

//   // Handle removing items from the cart
//   const removeFromCart = () => {
//     setCart([]);
//   };

//   // Calculate the total amount
//   const calculateTotal = () => {
//     return cart.reduce((total, item) => total + item.amount, 0);
//   };

//   return (
//     <div className="payment-form-container">
//       <h3>List of Exams</h3>

//       <table>
//         <thead>
//           <tr>
//             <th>S.No</th>
//             <th>Title</th>
//             <th>Amount</th>
//             <th>Cart</th>
//           </tr>
//         </thead>
//         <tbody>
//           {initialItems.map((item, index) => (
//             <tr key={item.id}>
//               <td>{index + 1}</td>
//               <td>{item.title}</td>
//               <td>{item.amount}</td>
//               <td>
//                 <button className='AddToCart' onClick={() => addToCart(item)}>
//                  Select
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Cart Table */}
//       {cart.length > 0 && (
//         <div className="cart-container">
//           <h3>Your Selected Exam.</h3>
//           <table>
//             <thead>
//               <tr>
//                 <th>Title</th>
//                 <th>Sub Total</th>
//                 <th>Remove</th>
//               </tr>
//             </thead>
//             <tbody>
//               {cart.map((item, index) => (
//                 <tr key={index}>
//                   <td>{item.title}</td>
//                   <td>{item.amount}</td>
//                   <td>
//                     <button onClick={removeFromCart}>Remove</button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>

//           {/* Total and Checkout Button */}
//           <div className="total-container">
//             <h3>Total: ₹{calculateTotal()}</h3>
//               <Link to='/billing-info'>
//             <button className="checkout-btn" onClick={() => alert('Proceeding to checkout')}>
//               Checkout
//             </button>
//             </Link>

//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PaymentForm;



// new new code 

import React, { useState, useEffect } from 'react';
import './PaymentForm.css'; // Import the CSS
import { Link } from 'react-router-dom';
import axios from 'axios';

const PaymentForm = () => {
  // Initial table data
  // const initialItems = [
  //   { id: 1, title: 'GEM Sustainability Program & GEM CP Exam - For Students and Faculty members (Rs. 4500/- + 18 % GST)', amount: 5310.00 },
  //   { id: 2, title: 'GEM Sustainability Program & GEM CP Exam - For Professionals (Rs. 6000/- + 18 % GST)', amount: 7080.00 },
  // ];

  // State to manage items in the cart
  const [cart, setCart] = useState([]);
  const [initialItems, setInitialItems] = useState([]); // To store exams fetched from the backend

  // Load cart data from local storage when the component mounts
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    if (savedCart) {
      setCart(savedCart);
    }
  }, []);
  console.log(cart)

  // Save cart data to local storage whenever the cart changes
  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem('cart', JSON.stringify(cart));
    }
  }, [cart]);

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    if (savedCart) {
      setCart(savedCart);
    }

    // Fetch exams from the backend
    fetchExams();
  }, []);

  // Handle adding items to the cart (only one item at a time)
  const addToCart = (item) => {
    // Only add the item if it's not already in the cart
    setCart([item]);
  };

  // Handle removing items from the cart
  const removeFromCart = () => {
    setCart([]);
  };

  // Calculate the total amount
  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.amount, 0);
  };

  const fetchExams = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URLII}/api/billing/exams`);
      setInitialItems(response.data); // Store the exams in the state
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };


  return (
    <div className="payment-form-container">
      <h3>List of Exams</h3>

      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Title</th>
            <th>Amount</th>
            <th>Cart</th>
          </tr>
        </thead>
        <tbody>
          {initialItems.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.title}</td>
              <td>{item.amount}</td>
              <td>
                <button className='AddToCart' onClick={() => addToCart(item)}>
                 Select
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Cart Table */}
      {cart.length > 0 && (
        <div className="cart-container">
          <h3>Your Selected Exam.</h3>
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Sub Total</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item, index) => (
                <tr key={index}>
                  <td>{item.title}</td>
                  <td>{item.amount}</td>
                  <td>
                    <button onClick={removeFromCart}>Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Total and Checkout Button */}
          <div className="total-container">
            <h3>Total: ₹{calculateTotal()}</h3>
              <Link to='/billing-info'>
            <button className="checkout-btn" onClick={() => alert('Proceeding to checkout')}>
              Checkout
            </button>
            </Link>

          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
