


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './GemExam.css';
// import { useParams, useNavigate } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';

// const GemExam = () => {
//   const { rollNumber } = useParams();
//   const [questions, setQuestions] = useState([]);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedOption, setSelectedOption] = useState('');
//   const [timeRemaining, setTimeRemaining] = useState(0); // timeRemaining state
//   const [isTimerRunning, setIsTimerRunning] = useState(true);
//   const [answeredQuestions, setAnsweredQuestions] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Fetch questions and time remaining from the backend
//     axios
//       .get(`${process.env.REACT_APP_API_URL}/api/exam/assigned-questions/${rollNumber}`)
//       .then((response) => {
//         if (response.data && response.data.questions) {
//           setQuestions(response.data.questions);
//           setTimeRemaining(response.data.timeRemaining); // Set timeRemaining from the backend
//         } else {
//           console.error('No questions or time remaining found');
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching assigned questions:', error);
//         if (error.response && error.response.status === 403) {
//           toast.error(error.response && error.response.status === 403)
//           navigate('/exam-message');
//         }
//       });

//     // Timer logic
//     const interval = setInterval(() => {
//       setTimeRemaining((prevTime) => {
//         if (prevTime <= 1) {
//           clearInterval(interval);
//           setIsTimerRunning(false);
//           handleSubmit(); // Auto-submit when time runs out
//           return 0;
//         }
//         return prevTime - 1;
//       });
//     }, 1000);

//     return () => clearInterval(interval); // Clean up interval on unmount
//   }, [rollNumber, navigate]);

//   // Function definitions

//   const handleOptionChange = (option) => {
//     if (isTimerRunning) {
//       setSelectedOption(option);
//     }
//   };

//   const handlePrevious = () => {
//     if (isTimerRunning && currentIndex > 0) {
//       setCurrentIndex(currentIndex - 1);
//       setSelectedOption(answeredQuestions[currentIndex - 1] || '');
//     }
//   };

//   const handleNext = () => {
//     if (isTimerRunning) {
//       handleAnswerSubmission();

//       if (currentIndex < questions.length - 1) {
//         setCurrentIndex(currentIndex + 1);
//         setSelectedOption(answeredQuestions[currentIndex + 1] || '');
//       }
//     }
//   };

//   const handleAnswerSubmission = () => {
//     const currentQuestion = questions[currentIndex];

//     if (selectedOption) {
//       axios
//         .post(`${process.env.REACT_APP_API_URL}/api/exam/store-answer1`, {
//           rollNumber,
//           ques_id: currentQuestion.ques_id,
//           question: currentQuestion.question,
//           selectedOption,
//           rightAns: currentQuestion.right_ans,
//         })
//         .then((response) => {
//           console.log('Answer submitted successfully:', response.data);
//         })
//         .catch((error) => {
//           console.error('Error submitting answer:', error);
//         });
//     }
//     setAnsweredQuestions((prev) => [...prev, currentIndex]);
//   };

//   const handleSubmit = () => {
//     handleAnswerSubmission();
//     alert(`Test Submitted!`);
//     navigate('/exam-message');
//   };

//   // Prevent rendering before questions are fetched
//   if (questions.length === 0) {
//     return <div>Loading questions...</div>;
//   }

//   const currentQuestion = questions[currentIndex];

//   // Convert remaining time to minutes and seconds
//   const minutes = Math.floor(timeRemaining / 60);
//   const seconds = timeRemaining % 60;
//   const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

//   return (
//     <div>
//       <div className="exam-container">
//         <div className="timer">
//           <p className="exam-timer">Time Remaining: {formattedTime}</p>
//         </div>

//         <div className="question-container">
//           <p className="question">
//             <strong>Question {currentIndex + 1}:</strong> {currentQuestion.question}
//           </p>
//           <div className="options">
//             {[1, 2, 3, 4].map((optIndex) => {
//               const optionLabel = currentQuestion[`opt${optIndex}`];
//               if (optionLabel) {
//                 return (
//                   <label className="question-option" key={optIndex}>
//                     <input
//                       className="exam-option-box"
//                       type="radio"
//                       name="answer"
//                       value={`opt${optIndex}`}
//                       checked={selectedOption === `opt${optIndex}`}
//                       onChange={() => handleOptionChange(`opt${optIndex}`)}
//                       disabled={!isTimerRunning}
//                     />
//                     {optionLabel}
//                   </label>
//                 );
//               }
//               return null;
//             })}
//           </div>
//         </div>

//         <div className="buttons">
//           <button
//             onClick={handlePrevious}
//             disabled={currentIndex === 0 || !isTimerRunning}
//           >
//             Previous
//           </button>
//           <button
//             onClick={handleNext}
//             disabled={currentIndex === questions.length - 1 || !isTimerRunning}
//           >
//             Next
//           </button>
//           <button
//             onClick={handleSubmit}
//             disabled={!isTimerRunning}
//           >
//             Submit
//           </button>
//         </div>

        
//       </div>
//       <ToastContainer />
//     </div>
//   );
// };

// export default GemExam;



// new new code 

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './GemExam.css';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const GemExam = () => {
  const { rollNumber } = useParams();
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(2700); // Default to 45 minutes
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch questions and time remaining from the backend
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/exam/assigned-questions/${rollNumber}`)
      .then((response) => {
        if (response.data && response.data.questions) {
          setQuestions(response.data.questions);
          setTimeRemaining(response.data.timeRemaining || 2700); // Fallback to 45 minutes
        } else {
          console.error('No questions or time remaining found');
        }
      })
      .catch((error) => {
        console.error('Error fetching assigned questions:', error);
        if (error.response && error.response.status === 403) {
          toast.error('You are not authorized to access this exam.');
          navigate('/exam-message');
        }
      });

    // Timer logic
    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          setIsTimerRunning(false);
          handleSubmit(); // Auto-submit when time runs out
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [rollNumber, navigate]);

  const handleOptionChange = (option) => {
    if (isTimerRunning) {
      setSelectedOption(option);
    }
  };

  const handlePrevious = () => {
    if (isTimerRunning && currentIndex > 0) {
      handleAnswerSubmission(); // Submit the current answer before moving
      setCurrentIndex(currentIndex - 1);
      const prevAnswer = answeredQuestions.find((q) => q.index === currentIndex - 1);
      setSelectedOption(prevAnswer?.selectedOption || '');
    }
  };

  const handleNext = () => {
    if (isTimerRunning) {
      handleAnswerSubmission(); // Submit the current answer before moving
      if (currentIndex < questions.length - 1) {
        setCurrentIndex(currentIndex + 1);
        const nextAnswer = answeredQuestions.find((q) => q.index === currentIndex + 1);
        setSelectedOption(nextAnswer?.selectedOption || '');
      }
    }
  };

  const handleAnswerSubmission = () => {
    const currentQuestion = questions[currentIndex];

    if (selectedOption) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/exam/store-answer1`, {
          rollNumber,
          ques_id: currentQuestion.ques_id,
          question: currentQuestion.question,
          selectedOption,
          rightAns: currentQuestion.right_ans,
        })
        .then((response) => {
          console.log('Answer submitted successfully:', response.data);
          setAnsweredQuestions((prev) => [
            ...prev,
            { index: currentIndex, selectedOption },
          ]);
        })
        .catch((error) => {
          console.error('Error submitting answer:', error);
        });
    }
  };

  const handleSubmit = async () => {
    await handleAnswerSubmission(); // Wait for the answer to be submitted
    alert(`Test Submitted!`);
    navigate('/exam-message');
  };

  if (questions.length === 0) {
    return <div>Loading questions...</div>;
  }

  const currentQuestion = questions[currentIndex];

  // Convert remaining time to minutes and seconds
  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;
  const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  // Collect available options dynamically
  const options = [];
  for (let i = 1; i <= 4; i++) {
    const option = currentQuestion[`opt${i}`];
    if (option) options.push({ value: `opt${i}`, label: option });
  }

  return (
    <div>
      <div className="exam-container">
        <div className="timer">
          <p className="exam-timer">Time Remaining: {formattedTime}</p>
        </div>

        <div className="question-container">
          <p className="question">
            <strong>Question {currentIndex + 1}:</strong> {currentQuestion.question}
          </p>
          <div className="options">
            {options.map(({ value, label }) => (
              <label className="question-option" key={value}>
                <input
                  className="exam-option-box"
                  type="radio"
                  name="answer"
                  value={value}
                  checked={selectedOption === value}
                  onChange={() => handleOptionChange(value)}
                  disabled={!isTimerRunning}
                />
                {label}
              </label>
            ))}
          </div>
        </div>

        <div className="buttons">
          <button
            onClick={handlePrevious}
            disabled={currentIndex === 0 || !isTimerRunning}
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            disabled={currentIndex === questions.length - 1 || !isTimerRunning}
          >
            Next
          </button>
          <button
            onClick={handleSubmit}
            disabled={!isTimerRunning}
          >
            Submit
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default GemExam;